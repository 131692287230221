@import (reference) "../../static/styles.less";


.open-banking-choice-style{
  .bank-icon-style {
    margin-top: 4rem;
  }
  .open-banking-choice-container{
    display: flex;
    flex-direction: column;
    align-items:center;
    gap: 1.5rem;
    width: 80%;
    margin:auto;
    @media @isMobile{
        width: 100%;
    }
}
}

#ocrolus-inline-iframe {
    border: none!important; /* Remove border */
    height: 20rem!important;
  }
  